import React from "react"
import { graphql } from "gatsby"
// import Seo from "gatsby-plugin-wpgraphql-seo"
import Layout from "../components/layout"

import ProductCard from '../components/template-parts/product-card'

import Hero from "../components/template-parts/hero"

import ShopNav from '../components/template-parts/shop-nav'
import '../scss/shop.scss'

const CollectionTemplate = ({ data: { allShopifyProduct: { products } }, pageContext }) => {   

  return (
    <Layout isProductPage={false}>
            <Hero page={{ hero: { title: pageContext.title } }} />
            <section className="product-list">

                <div className="container container-shop-nav">
                    <ShopNav />
                </div>

                <div className="container">
                    {products.map(product => (
                        <ProductCard key={product.id} className="product" product={{
                            title: product.title,
                            priceRangeV2: product.priceRangeV2,
                            slug: `/products/${product.handle}`,
                            images: [product.images[0]],
                            storefrontImages: product.images,
                            vendor: product.vendor,
                        }} />
                    ))}
                </div>
            </section>
        
    </Layout>
  )
}

export default CollectionTemplate

export const pageQuery = graphql`#graphql
    query ProductsByCollection( $title: String! ) {
        allShopifyProduct(filter: { collections: { elemMatch: { title: { eq: $title } } } }) {
            products: nodes {
                id
                title
                description
                productType
                tags
                handle
                priceRangeV2 {
                    maxVariantPrice {
                        amount
                        currencyCode
                    }
                    minVariantPrice {
                        amount
                        currencyCode
                    }
                }
                storefrontId
                images {
                    altText
                    id
                    gatsbyImageData(
                        layout: FULL_WIDTH,
                        aspectRatio: 1
                    )
                }
                variants {
                    availableForSale
                    storefrontId
                    title
                    price
                    selectedOptions {
                        name
                        value
                    }
                }
                options {
                    name
                    values
                    id
                }
            }
        }
    }
`
